import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
import Countries from "../../../../static/countries.json"
import P from "../../core/P.js"
import H2 from "../../core/H2.js"
import { Approx_Budget, categorizes, logo_images } from "../../../util/jsonData"
import { GetInTouch } from "../../core/GetInTouch"
import H3 from "../../core/H3"
import { FlagIcon } from "react-flag-kit";

const NewExtendedContactForm = () => {
    const selectRef = useRef(null);
    const [data, setData] = useState([]);
    const [isShowDropdown, setIsShowDropdown] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [nda, setNda] = useState(false)
    const [countryCode, setCountryCode] = useState("")
    const [message, setMessage] = useState(undefined)
    const [status, setStatus] = useState(undefined)
    const [disabled, setDisabled] = useState(false)
    // const [optionsCountry, setOptionsCountry] = useState([])
    const [categorizesValue, setCategorizesValue] = useState("")
    const [budget, setBudget] = useState('')
    // const [howSoon, setHowSoon] = useState('')
    // const [optionCategorizes, setOptionCategorizes] = useState()
    // eslint-disable-next-line no-unused-vars
    const [file, setFile] = useState(undefined)
    const [isRecaptcha, setIsRecaptcha] = useState(false)
    const [recaptchaComponent, setRecaptchaComponent] = useState(null);
    const captchaRef = useRef(null);

    useEffect(() => {
        const timeout = setTimeout(async () => {
          // Dynamically import the package after 9 seconds
          const { default: ReCAPTCHA } = await import('react-google-recaptcha');
          setRecaptchaComponent(<ReCAPTCHA
            sitekey="6Ld19jMnAAAAAH9Y24lduuFjNMmBZEWaTwLwxLQE"
            onChange={onChangeRecaptcha}
            onErrored={onErroredRecaptcha}
            onExpired={onExpiredRecaptcha}
            ref={captchaRef}
            className="recaptch" />);
        }, 9000); // Delay of 9 seconds
    
        return () => clearTimeout(timeout);
      }, []);

    const onChangeRecaptcha = () => {
        setIsRecaptcha(true);
    }
    const onErroredRecaptcha = () => {
        setIsRecaptcha(false);
    }
    const onExpiredRecaptcha = () => {
        setIsRecaptcha(false);
    }

    function paginate(array, page_size, page_number) {
        const start_index = (page_number - 1) * page_size;
        const end_index = start_index + page_size;
        setPageNumber(pageNumber + 1)
        return array?.slice(start_index, end_index);
    }
    useEffect(() => {
        if(!(pageNumber-1)){
            const start_index = (pageNumber - 1) * 10;
            const end_index = start_index + 10;
            setData(Countries?.slice(start_index, end_index))
            setPageNumber(pageNumber + 1)
        }
    }, [pageNumber])
    function handleScroll(event) {
        const element = event.target;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            let newData = paginate(Countries, 10, pageNumber);
            setData(prevData => [...prevData, ...newData]);
        }
    }
    const onClickSelectBox = () => {
        setIsShowDropdown(!isShowDropdown)
    }
    const handleKeyDownSelect = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            onClickSelectBox();
        }
    };
    const handleKeyDownSelectOption = (event,item) => {
        if (event.key === "Enter" || event.key === " ") {
            onClickCountryOption(item)
        }
    };
    const onClickCountryOption = (e) => {
        setCountryCode(`${e.flag} ${e?.code} (${e?.dial_code}) `)
        setIsShowDropdown(false)
    }

    // const changeHandler = e => {
    //     setCountryCode(e.target.value)
    //     // setCountryCode(e.label)
    // }
    // const handleOption = () => {
    //     const test = Countries
    //     setOptionsCountry(test)
    // }
    const handleCategorizes = e => {
        setCategorizesValue(e.target.value)
    }
    const handleBudget = e => {
        setBudget(e.target.value)
    }
    const onSubmit = e => {
        e.preventDefault()
        setDisabled(true)
        const form = e.target
        const data = new FormData(form)
        data.set("countryCode", countryCode)
        data.set("nda", nda ? "1" : "0")
        data.set("recaptcha_token", captchaRef.current.getValue())
        // eslint-disable-next-line no-unused-vars
        const response = fetch(
            // "http://127.0.0.1:8080/api/web/contactus/extended_contact_submit",
            "https://api.webmobtech.com/wmtblog/api/web/contactus/extended_contact_submit",
            {
                method: "POST",
                body: data,
            }
        )
            .then(response => {
                return response.json()
            })
            .then(res => {
                window.scrollTo(0, 0)
                if (res.meta.status === "ok") {
                    document.getElementById("contact-form").reset()
                    setNda(false)
                    navigate("/thank-you/")
                }
                setDisabled(false)
                setMessage(res.meta.message)
                setStatus(res.meta.status)
            })
    }
    useEffect(() => {
        fetch('https://ipapi.co/json/')
            .then(function (response) {
                return response.json();
            })
            .then(function (payload) {
                Countries.sort((x, y) => { return x.code === payload?.country_code ? -1 : y.code === payload?.country_code ? 1 : 0; });
                setCountryCode(`${Countries[0].flag} ${Countries[0].code} (${Countries[0].dial_code}) `)
                // setOptionsCountry(Countries)
            });
    }, [])
    const Notification = ({ status, message }) => {
        if (!status) return null
        return (
            status && (
                <div
                    className={` rounded-md ${status === "ok" ? "bg-green-50" : "bg-red-50"
                        } p-4`}
                >
                    <div className="flex">
                        <div className="ml-3">
                            <p
                                className={`text-sm leading-5 font-medium ${status === "ok" ? "text-green-800" : "text-red-800"
                                    }`}
                            >
                                {message}
                            </p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    onClick={() => setStatus(false)}
                                    className={`inline-flex rounded-md p-1.5 ${status === "ok"
                                        ? "text-green-500 focus:bg-green-100 hover:bg-green-100"
                                        : "text-red-500 focus:bg-red-100 hover:bg-red-100"
                                        }  focus:outline-none transition ease-in-out duration-150`}
                                >
                                    <svg
                                        className="h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }

    return (
        <div className="common-index flex flex-wrap lg:flex-nowrap lg:pt-36 pt-24 pb-24 max-w-screen-xl mx-auto gap-12">
            <div className="overflow-hidden w-full lg:w-7/12 ">
                <div className="relative lg:px-10 px-4 pt-5 pb-10 bg-white rounded-md ">
                    <form
                        id="contact-form"
                        method="POST"
                        encType="multipart/form-data"
                        className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-6 "
                        onSubmit={e => onSubmit(e)}
                    >
                        <div className="sm:col-span-2">
                            <H2
                                title={`Request for Project Proposal`}
                                className={`text-shark-400 py-6 text-left`}
                                noPad
                            />
                        </div>
                        {status && (
                            <div className="sm:col-span-2">
                                <Notification status={status} message={message} />
                            </div>
                        )}
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="document"
                                className="text-shark-500 font-medium block text-lg leading-5 "
                            >
                                1. Tell us about you and your project
                            </label>
                        </div>
                        <div className="sm:col-span-2 relative  shadow-sm">
                            <input
                                placeholder="Full Name *"
                                id="fullName"
                                name="fullName"
                                required
                                className="input_custom_placeholder rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-shark-50"
                            />
                        </div>
                        <div className="sm:col-span-2 relative  shadow-sm">
                            <input
                                placeholder="Email *"
                                id="emailId"
                                name="emailId"
                                type="email"
                                required
                                className="input_custom_placeholder rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-shark-50 "
                            />
                        </div>
                        {/* <div className="lg:col-span-1 md:col-span-2 col-span-1 relative  shadow-sm">
                            <input
                                placeholder="Skype ID/WhatsApp No."
                                id="skypeOrWhatsapp"
                                name="skypeOrWhatsapp"
                                className="input_custom_placeholder rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-shark-50 "
                            />
                        </div> */}
                        <div className="sm:col-span-2 flex flex-wrap">
                            <div className="lg:w-1/3 w-1/2 lg:pr-0 pr-2 relative shadow-sm">
                            <div className='rounded-md bg-transparent form-input py-3 px-4 flex items-center gap-2 w-full border border-solid  border-shark-50 transition ease-in-out duration-150 text-sm cursor-pointer focus:border-blue-500' ref={selectRef} onClick={onClickSelectBox} role='button' tabIndex="0" onKeyDown={handleKeyDownSelect}>
                                    {countryCode ? <><FlagIcon code={countryCode?.split(" ")[1]} style={{ width: '16px', height: '13px' }} /><span>{countryCode?.slice(4)}</span></> : 'Country Code *'}
                                </div>

                                {
                                    isShowDropdown ?
                                        <div style={{ overflowY: 'scroll', height: '150px' }} className='absolute z-10 bg-white rounded-md select-height form-select py-3 px-4 block w-full h-full border border-solid border-blue-300 transition ease-in-out duration-150 text-sm cursor-pointer' onScroll={handleScroll} onTouchEnd={handleScroll}>
                                            {data.map((item, index) => (
                                                <span role='button' tabIndex="0" onKeyDown={(e)=>handleKeyDownSelectOption(e,item)}  key={index} className='cursor-pointer flex items-center py-1' onClick={() => onClickCountryOption(item)}><FlagIcon code={item?.code} style={{ width: '16px', height: '13px' }} />&nbsp;{item.code}&nbsp;({item.dial_code})</span>
                                            ))}
                                        </div>
                                        : null
                                }
                                {/* <select
                                    placeholder="Country Code *"
                                    aria-label="Country"
                                    name="countryCode"
                                    options={Countries}
                                    value={countryCode}
                                    onFocus={handleOption}
                                    onChange={changeHandler}
                                    required
                                    className="rounded-md bg-transparent text-shark-300 form-select py-2 px-4 block w-full h-full border border-solid border-shark-50"
                                >
                                    {optionsCountry &&
                                        optionsCountry.map(e => (
                                            <option
                                                className="custom_option border-shark-50"
                                                key={Math.random()}
                                            >
                                                {e.code}&nbsp;&nbsp;({e.dial_code})&nbsp;&nbsp;{e.flag}
                                            </option>
                                        ))}
                                </select> */}
                            </div>
                            <div className="lg:w-2/3 w-1/2 lg:pl-6  pl-2 relative  shadow-sm">
                                <input
                                    placeholder="Mobile Phone Number *"
                                    type="tel"
                                    id="contactNo"
                                    name="contactNo"
                                    required
                                    className="input_custom_placeholder rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-shark-50 "
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2 relative shadow-sm">
                            <input
                                placeholder="Company Name"
                                id="companyName"
                                name="companyname"
                                className="input_custom_placeholder rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-shark-50 "
                            />
                        </div>
                        <div className="sm:col-span-2 relative shadow-sm">
                            <input
                                placeholder="Share reference links or name."
                                id="reference_link"
                                name="referenceLink"
                                type="text"
                                className="input_custom_placeholder rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid border-shark-50 "
                            />
                        </div>
                        {/* <div className="sm:col-span-2 relative  shadow-sm"> */}
                        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                        {/* <select
                                aria-label="howSoon"
                                name="howSoon"
                                options={How_soon}
                                value={howSoon}
                                onChange={(e)=>setHowSoon(e.target.value)}
                                required
                                className="rounded-md bg-transparent form-select py-2 px-4 block w-full h-full border border-solid text-shark-400 border-shark-50 transition ease-in-out duration-150 "
                            >
                                <option value="" className="custom_option text-shark-500">
                                    How soon you want to start? *
                                </option>
                                {How_soon &&
                                    How_soon.map(e => (
                                        <option
                                            className="custom_option text-shark-500"
                                            key={Math.random()}
                                        >
                                            {e}
                                        </option>
                                    ))}
                            </select> */}
                        {/* </div> */}
                        <div className="sm:col-span-2 relative shadow-sm">
                            <textarea
                                placeholder="Share brief about your project requirement *"
                                id="desc"
                                name="desc"
                                rows="3"
                                required
                                className="input_custom_placeholder rounded-md bg-transparent form-textarea resize-none py-2 px-4 block w-full border border-solid  border-shark-50 "
                            ></textarea>
                        </div>
                        <div className="lg:col-span-1 md:col-span-1 col-span-1 relative  shadow-sm">
                            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                            <select
                                placeholder="Budget*"
                                aria-label="budget"
                                name="budget"
                                options={Approx_Budget}
                                value={budget}
                                onChange={handleBudget}
                                required
                                className="rounded-md bg-transparent form-select py-2 px-4 block w-full h-full border border-solid text-shark-400 border-shark-50 transition ease-in-out duration-150 "
                            >
                                <option value="" className="custom_option text-shark-500">
                                    Budget *
                                </option>
                                {Approx_Budget &&
                                    Approx_Budget.map(e => (
                                        <option
                                            className="custom_option text-shark-500"
                                            key={Math.random()}
                                        >
                                            {e}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="lg:col-span-1 md:col-span-1 col-span-1 relative  shadow-sm">
                            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                            <select
                                placeholder="Your Business Category *"
                                aria-label="category"
                                name="category"
                                options={categorizes}
                                value={categorizesValue}
                                // onFocus={handleOption}
                                onChange={handleCategorizes}
                                required
                                className="rounded-md bg-transparent form-select py-2 px-4 block w-full h-full border border-solid text-shark-400 border-shark-50 transition ease-in-out duration-150 "
                            >
                                <option value="" className="custom_option text-shark-500">
                                    Your Business Categorizes *
                                </option>
                                {categorizes &&
                                    categorizes.map(e => (
                                        <option
                                            className="custom_option text-shark-500"
                                            key={Math.random()}
                                        >
                                            {e}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="services_category"
                                className="text-shark-500 font-medium mb-4 block text-lg leading-5 "
                            >
                                2. What Services You are Looking For? *
                            </label>
                            <div className="flex flex-wrap gap-4  md:justify-start">
                                <div>
                                    <label htmlFor="radio5">
                                        <div className="text-center hover:text-blue-500 bg-white rounded-md">
                                            <input
                                                type="checkbox"
                                                id="radio5"
                                                value="AI/ML"
                                                name="services[]"
                                            />
                                            <div className="customcheck p-4 py-2 rounded-md border border-shark-50 ">
                                                <P
                                                    title={`AI/ML`}
                                                    className={`text-xs text-center mx-3`}
                                                    textSize="text-base"
                                                    noPad
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="radio6">
                                        <div className="text-center hover:text-blue-500 bg-white rounded-md">
                                            <input
                                                type="checkbox"
                                                id="radio6"
                                                value="Web App"
                                                name="services[]"
                                            />
                                            <div className="customcheck p-4 py-2 rounded-md border border-shark-50 ">
                                                {/* <i
                          className={`icon-web_app_dev flex justify-center py-3 mx-0 active:text-blue-500`}
                          style={{ fontSize: "50px" }}
                        /> */}
                                                <P
                                                    title={`Web App`}
                                                    className={`text-xs text-center mx-3`}
                                                    textSize="text-base"
                                                    noPad
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="radio7">
                                        <div className="text-center hover:text-blue-500 bg-white rounded-md">
                                            <input
                                                type="checkbox"
                                                id="radio7"
                                                value="Mobile App"
                                                name="services[]"
                                            />
                                            <div className="customcheck p-4 py-2 rounded-md border border-shark-50">
                                                <P
                                                    title={`Mobile App`}
                                                    className={` text-center mx-3`}
                                                    noPad
                                                    textSize="text-base"
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="radio8">
                                        <div className="text-center hover:text-blue-500 bg-white rounded-md">
                                            <input
                                                type="checkbox"
                                                id="radio8"
                                                value="UI/UX"
                                                name="services[]"
                                            />
                                            <div className="customcheck p-4 py-2 rounded-md border border-shark-50">
                                                <P
                                                    title={`UI/UX`}
                                                    className={` text-center mx-3`}
                                                    noPad
                                                    textSize="text-base"
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="radio9">
                                        <div className="text-center hover:text-blue-500 bg-white rounded-md">
                                            <input
                                                type="checkbox"
                                                id="radio9"
                                                value="IT Consulting"
                                                name="services[]"
                                            />
                                            <div className="customcheck p-4 py-2 rounded-md border border-shark-50">
                                                <P
                                                    title={`IT Consulting`}
                                                    className={` text-center mx-3`}
                                                    noPad
                                                    textSize="text-base"
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="radio10">
                                        <div className="text-center hover:text-blue-500 bg-white rounded-md">
                                            <input
                                                type="checkbox"
                                                id="radio10"
                                                value="DevOps"
                                                name="services[]"
                                            />
                                            <div className="customcheck p-4 py-2 rounded-md border border-shark-50">
                                                <P
                                                    title={`DevOps`}
                                                    className={` text-center mx-3`}
                                                    noPad
                                                    textSize="text-base"
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="radio11">
                                        <div className="text-center hover:text-blue-500 bg-white rounded-md">
                                            <input
                                                type="checkbox"
                                                id="radio11"
                                                value="AR/VR"
                                                name="services[]"
                                            />
                                            <div className="customcheck p-4 py-2 rounded-md border border-shark-50 active:bg-blue-500">
                                                <P
                                                    title={`AR/VR`}
                                                    className={` text-center mx-3`}
                                                    noPad
                                                    textSize="text-base"
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="radio12">
                                        <div className="text-center hover:text-blue-500 bg-white rounded-md">
                                            <input
                                                type="checkbox"
                                                id="radio12"
                                                value="IoT"
                                                name="services[]"
                                            />
                                            <div className="customcheck p-4 py-2 rounded-md border border-shark-50">
                                                <P
                                                    title={`IoT`}
                                                    className={` text-center mx-3`}
                                                    noPad
                                                    textSize="text-base"
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="radio13">
                                        <div className="text-center hover:text-blue-500 bg-white rounded-md">
                                            <input
                                                type="checkbox"
                                                id="radio13"
                                                value="Hire Dedicated Developers"
                                                name="services[]"
                                            />
                                            <div className="customcheck p-4 py-2 rounded-md border border-shark-50 bg-blue-400">
                                                <P
                                                    title={`Hire Dedicated Developers`}
                                                    className={` text-center mx-3`}
                                                    noPad
                                                    textSize="text-base"
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="radio14">
                                        <div className="text-center hover:text-blue-500 bg-white rounded-md">
                                            <input
                                                type="checkbox"
                                                id="radio14"
                                                value="Others"
                                                name="services[]"
                                            />
                                            <div className="customcheck p-4 py-2 rounded-md border border-shark-50 bg-blue-400">
                                                <P
                                                    title={`Others`}
                                                    className={` text-center mx-3`}
                                                    noPad
                                                    textSize="text-base"
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label
                                htmlFor="document"
                                className="text-shark-500 font-medium mb-3 block text-lg leading-5 "
                            >
                                3. Upload Project Documents.
                            </label>
                            <div className="mt-5">
                                <label className="w-64 flex-inline flex-col items-center px-4 py-2 bg-blue-200 text-blue rounded-lg shadow-lg tracking-wide border border-blue cursor-pointer">
                                    <span className=" text-base leading-normal">Choose File</span>
                                    <input
                                        onChange={event => setFile(event?.target?.files[0]?.name)}
                                        type="file"
                                        id="uploadFile"
                                        name="fileDoc"
                                        accept=".jpg, .jpeg, .png,.doc,.docx,.xml, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint"
                                        className="hidden"
                                    />
                                </label>
                                <div className="inline-block text-ellipsis text-blue-500 pl-2 ">
                                    {file ? file : 'No file chosen'}
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <div >
                                <label className="flex items-start mt-4 mb-2  text-shark-500 text-base" for="agreement">
                                    <input type="checkbox" required className="checkid leading-none w-4 mr-2" id="agreement" name="agreement" value="I agree to receive information about WebMobTech's news, products, & updates." />
                                    I agree to receive information about WebMobTech's news, products, & updates.
                                </label>
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <div >
                                {recaptchaComponent}
                            </div>
                        </div>
                        <hr className="sm:col-span-2" />
                        <div className="sm:col-span-2">
                            <div className="flex justify-between">
                                {/* <span className="inline-flex items-center my-3">
                                    <span
                                        className={`${nda ? "bg-blue-500" : "bg-blue-300"
                                            } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                                        role="checkbox"
                                        aria-checked="false"
                                        aria-hidden="true"
                                        onClick={() => setNda(s => !s)}
                                        id="nda"
                                        name="nda"
                                    >
                                        <span
                                            className={` ${nda ? "translate-x-5" : "translate-x-0"
                                                } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                                        ></span>
                                    </span>
                                    <span className="ml-2 block text-lg font-medium leading-5 text-shark-400">
                                        Request NDA
                                    </span>
                                </span> */}
                                <span className="inline-flex items-center text-shark-500 font-medium">
                                    Serving clients since 2010
                                </span>
                                <span className=" inline-flex shadow-sm">
                                    {disabled ? (
                                        <button
                                            type="submit"
                                            disabled
                                            className="bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white opacity-75 cursor-not-allowed rounded"
                                        >
                                            Get Started
                                        </button>
                                    ) : (
                                        <button
                                            disabled={!isRecaptcha ? true : false}
                                            type="submit"
                                            className={isRecaptcha ? "bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded" : "bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white opacity-75 cursor-not-allowed rounded"}
                                        >
                                            Get Started
                                        </button>
                                    )}
                                    <div className=" mt-5 inline-flex "></div>
                                </span>
                            </div>
                        </div>


                    </form>
                </div>
            </div>
            <div className="lg:w-5/12 w-full flex flex-col lg:justify-center px-4 lg:px-0">
                <div>
                    <H3 title={'We are trusted by'} />
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-2 gap-y-4 md:gap-6 mt-6">
                        {
                            logo_images.map((val, index) => {
                                return <div key={index} className="w-full h-16 bg-white rounded-full shadow-xl">
                                    <img
                                        alt={val?.alt}
                                        src={val?.src}
                                        // title={val?.alt}
                                        className="w-full h-full object-contain"
                                    />
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="mt-10 lg:mt-16  ">
                    <GetInTouch />
                </div>
            </div>
        </div>
    )
}

export default NewExtendedContactForm
